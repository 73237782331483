import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.list,
    border: "",
    fit: "",
    "highlight-current-row": "",
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_el_table_column, {
        align: "center",
        label: "ID",
        width: "65",
        "element-loading-text": "请给我点时间！"
      }, {
        default: _withCtx(({row}) => [
          _createElementVNode("span", null, _toDisplayString(row.id), 1)
        ]),
        _: 1
      }, 512), [
        [_directive_loading, _ctx.loading]
      ]),
      _createVNode(_component_el_table_column, {
        width: "180px",
        align: "center",
        label: "Date"
      }, {
        default: _withCtx(({row}) => [
          _createElementVNode("span", null, _toDisplayString(row.timestamp), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        "min-width": "240px",
        label: "Title"
      }, {
        default: _withCtx(({row}) => [
          _createElementVNode("span", null, _toDisplayString(row.title), 1),
          _createVNode(_component_el_tag, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(row.type), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        width: "180px",
        align: "center",
        label: "Author"
      }, {
        default: _withCtx(({row}) => [
          _createElementVNode("span", null, _toDisplayString(row.author), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        width: "120px",
        label: "Importance"
      }, {
        default: _withCtx(({row}) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(+row.importance, (n) => {
            return (_openBlock(), _createBlock(_component_svg_icon, {
              key: n,
              name: "star"
            }))
          }), 128))
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        align: "center",
        label: "Readings",
        width: "95"
      }, {
        default: _withCtx(({row}) => [
          _createElementVNode("span", null, _toDisplayString(row.pageviews), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        "class-name": "status-col",
        label: "Status",
        width: "110"
      }, {
        default: _withCtx(({row}) => [
          _createVNode(_component_el_tag, {
            type: row.status 
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(row.status), 1)
            ]),
            _: 2
          }, 1032, ["type"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}